<template>
  <div>
    <v-alert
      :type="actionClass"
      class="mr-15"
      v-if="message"
      dismissible
    >
      {{ message }}
    </v-alert>
    <div v-if="getSession.user_type === 1">
      <v-list v-for="(file, index) in files" :key="index" dense class="transparent" >
        <v-list-item class="pa-0 mt-0" link dense @click="download(file)" > 
          <v-list-item-icon>
            <v-icon large class="mt-5">
              mdi-note
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ file.client_name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              <span class="successful text-capitalize"> {{ file.upload_type }}</span> {{ formatByte(file.file_size)}}
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-icon class=" ">
            <v-btn icon tile :color="modalColor" class="mt-4 icon-inverted">
              <v-icon color="green" small> mdi-greater-than </v-icon>
            </v-btn>
            <label class="mt-6 ">
              {{ determineDuration(file.created_at) }}
            </label>
          </v-list-item-icon>

          <v-list-item-action>
            <v-list-item-icon class="mt-5">
              <v-btn
                icon
                text
                outlined
                tile
                :color="modalColor"
                class="white--text"
                @click="removeFile(index, file.file_name)"
                v-if="false"
              >
                <!-- <v-icon color=""> mdi-delete </v-icon> -->
                <i class="far fa-trash-alt"></i>
              </v-btn>
              <v-btn
                icon
                text
                outlined
                tile
                :color="modalColor"
                class="white--text mt-n3 rounded-lg"
                @click="download(file)"
              >
                <v-icon color=""> mdi-download </v-icon>
              </v-btn>
            </v-list-item-icon>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
      </v-list>
    </div>
    <div v-if="getSession.user_type === 2">
      <v-list v-for="(file, index) in files" :key="index" dense class="transparent" >
        <v-tooltip
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <div  
            v-bind="attrs"
            v-on="on"
            >
              <v-list-item 
              class="pa-0 mt-0" 
              link 
              dense 
              disabled
              > 
              <v-list-item-icon>
                <v-icon large class="mt-5">
                  mdi-note
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ file.client_name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  <span class="successful text-capitalize"> {{ file.upload_type }}</span> {{ formatByte(file.file_size)}}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-icon class=" ">
                <v-btn icon tile :color="modalColor" class="mt-4 icon-inverted">
                  <v-icon color="green" small> mdi-greater-than </v-icon>
                </v-btn>
                <label class="mt-6 ">
                  {{ determineDuration(file.created_at) }}
                </label>
              </v-list-item-icon>

              <v-list-item-action>
                <v-list-item-icon class="mt-5">
                  <v-btn
                    icon
                    text
                    outlined
                    tile
                    :color="modalColor"
                    class="white--text"
                    @click="removeFile(index, file.file_name)"
                    v-if="false"
                  >
                    <i class="far fa-trash-alt"></i>
                  </v-btn>
                  <v-btn
                    icon
                    text
                    outlined
                    tile
                    :color="modalColor"
                    class="white--text mt-n3 rounded-lg"
                    @click="download(file)"
                  >
                    <v-icon color=""> mdi-download </v-icon>
                  </v-btn>
                </v-list-item-icon>
              </v-list-item-action>
            </v-list-item>
            </div>
            
          </template>
          <span> You are not allowed to  <br/> download this file</span>
        </v-tooltip>
        <v-divider></v-divider>
      </v-list>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import colorMxn from "@/mixins/colorMxn";
import generalMxn from '@/mixins/general_mixin';
import validationMxn from "@/mixins/validation_mixin";
import timeZoneMxn from "@/mixins/timezone_mixin";

export default {
  name: "fileList",
  props: ["files"],
  mixins: [colorMxn, validationMxn, generalMxn, timeZoneMxn],
  data() {
    return {
      actionClass: '',
      message: '',
    };
  },
  computed: {
    ...mapGetters(['getSession']),
  },
  methods: {
    ...mapMutations(['setRemoveUploadedFile','setRemoveUploadedAnswer']),
    async removeFile(key, fileName) {
      const payload = {
        file_name: fileName,
      }
      const fullPayload = {
        params: payload,
        endpoint: `/deleted`,
      };
      const response = await this.performPostActions(fullPayload);
      this.actionClass = response.data.status === "success" ? 'success': 'error';
      this.message = response.data.message;
      if (response.data.status === "success") {
        this.setRemoveUploadedFile(key);
      }
    },
    download(file) {
      const s3path = file.url ? file.url : `${process.env.VUE_APP_BASE_URL}/download/${file.file_name}`;
      window.open(s3path, '_blank');
    }
  }
};
</script>

<style scoped>
.icon-inverted {
  transform: rotate(270deg);
}
</style>
